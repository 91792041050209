<style>
.swal2-popup .swal2-icon {
  margin-left: 39% !important;
}
.multiselect__content-wrapper {
  width: 150% !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<template>
  <div class="card card-custom">
    <div class="card-body">
      <div class="row">
        <div class="col-md-4">
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Search by TCN"
              aria-describedby="basic-addon2"
              v-model="search_text"
              @keypress.enter="search()"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-info"
                type="button"
                @click="search()"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <canvas
          id="barcode"
          class="d-none"
          style="width: inherit; height: 95px"
        ></canvas>

        <div class="col-md-4 pr-0" v-if="!currentUser.u.is_client">
          <div class="form-group">
            <multiselect
              key="id"
              :options="getClients ? getClients : []"
              placeholder="Select Location "
              label="name"
              track-by="id"
              :preselect-first="false"
              v-model="serviceOutlet"
              @select="loadApiKeys"
            ></multiselect>
            <!-- <select
              @keypress.enter="appData()"
              v-model="serviceOutlet"
              @change="loadApiKeys()"
              name=""
              class="form-control"
              id=""
            >
              <option value="all">All</option>
              <option
                v-for="(citem, index) in getClients"
                :key="index"
                :value="citem.id"
              >
                {{
                  citem.display_name == null || citem.display_name == ""
                    ? citem.name
                    : citem.display_name
                }}
              </option>
            </select> -->
          </div>
        </div>
        <div class="col-md-3 pl-2 pr-0">
          <div class="form-group">
            <multiselect
              key="id"
              :options="getLogsApiKeys ? getLogsApiKeys : []"
              placeholder="Select WorkStation "
              label="label"
              track-by="id"
              :preselect-first="false"
              v-model="apikey"
            ></multiselect>
            <!-- <select
              @keypress.enter="appData()"
              v-model="apikey"
              name=""
              class="form-control"
              id=""
            >
              <option value="all">All</option>
              <option
                v-for="(citem, index) in getLogsApiKeys"
                :key="index"
                :value="citem.id"
              >
                {{ citem.label }}
              </option>
            </select> -->
          </div>
        </div>

        <div class="col-md-2 pl-2 pr-0">
          <div class="form-group">
            <input
              @keypress.enter="appData()"
              type="date"
              v-model="date_from"
              class="form-control"
            />
          </div>
        </div>

        <div class="col-md-2 pl-2 pr-0">
          <div class="form-group">
            <input
              @keypress.enter="appData()"
              type="date"
              v-model="date_to"
              class="form-control"
            />
          </div>
        </div>

        <div class="col-md-1">
          <button class="btn btn-outline-info" @click="advSearch()">
            <i class="fa fa-search"></i>
          </button>
        </div>
      </div>
      <hr />
      <div
        class="row"
        v-if="apikeylogs.length > 0 && !getLoadingData && AppType"
      >
        <div class="col-md-12">
          <!-- <download-excel
            class="btn btn-primary ml-3"
            style="float:right"
            :data="appointments"
            :fields="json_fields"
            :worksheet="date_from + ' - ' + date_to"
            name="Appointment List.xls"
          >
            <i class="test-white fa fa-download"></i> Download Excel
          </download-excel> -->
          <a
            @click="refreshData()"
            href="#"
            class="btn btn-secondary font-weight-bolder"
            style="float: right"
          >
            <i class="text-white fa fa-refresh"></i> Refresh</a
          >
        </div>
      </div>
      <hr v-if="apikeylogs.length > 0 && !getLoadingData && AppType" />
      <div class="row" v-if="apikeylogs.length > 0 && !getLoadingData">
        <div class="col-md-12">
          <v-table :columns="columns">
            <tr v-for="(item, index) in apikeylogs" :key="index">
              <td>{{ index + 1 }}</td>
              <td>
                <h6 style="font-size: 13px; font-weight: 400">
                  {{ datetimeFormatAmPm(item.log_date) }}
                </h6>
              </td>
              <td>
                <!-- <a
                  href="javascript:void;"
                  @click="
                    openAppointmentDetail(item.id, item.token, index, true)
                  "
                >
                  {{ item.applicant }}</a
                > -->
                {{ item.api_key_label }}
              </td>

              <td>
                {{ item.client_name }}
              </td>
              <td>
                {{ item.tcn }}
              </td>
              <td>
                {{ item.detail }}
              </td>
            </tr>
          </v-table>
        </div>
      </div>
      <div class="row" v-if="apikeylogs.length < 1 && !getLoadingData">
        <div class="col-md-12 text-center">
          <h4>no log(s) found</h4>
        </div>
      </div>

      <div class="row" v-if="getLoadingData">
        <div class="col-md-12 text-center">
          <v-data-loader></v-data-loader>
        </div>
      </div>
      <hr v-if="pagination.total_page > 1" />
      <vl-pagination
        v-on:selected="selectedPage"
        v-on:previous="previousPage"
        v-on:first="firstPage"
        v-on:last="lastPage"
        v-on:next="nextPage"
        v-if="pagination.total_page > 1"
        :pagination="pagination"
      ></vl-pagination>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {
  FETCH_CLIENTS,
  FETCH_CLIENT_APIKEYS,
  FETCH_APIKEY_LOGS
} from "@/core/services/store/actions.type";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import vTable from "@/includes/table/Datatable";
import Multiselect from "vue-multiselect";
import vlPagination from "@/includes/table/Pagination";
import { mapGetters } from "vuex";
import vDataLoader from "@/components/frontend/spinner.vue";
// import JsonExcel from "vue-json-excel";
export default {
  components: {
    vTable,
    vlPagination,
    vDataLoader,
    Multiselect
    // downloadExcel: JsonExcel
  },
  computed: {
    ...mapGetters(["currentUser", "getClients", "getLogsApiKeys"])
  },
  filters: {
    toFixedTwoDigit(amount) {
      return parseFloat(amount).toFixed(2);
    },
    timeFormat: function(value, date) {
      if (date == null) {
        return;
      }
      var fullDate = date + " " + value;
      var a = moment(fullDate).format("hh:mm a");
      return a;
    },
    dateFormat(date) {
      if (date == null) {
        return;
      }
      return moment(date).format("MM/DD/YYYY");
    }
  },
  data() {
    return {
      getLoadingData: false,
      columns: [
        {
          name: "S No.",
          width: "5%",
          label: "S No.",
          class: ""
        },
        {
          name: "date",
          width: "8%",
          label: "Date",
          class: ""
        },
        {
          name: "Workstation Label",
          width: "",
          label: "Workstation",
          class: ""
        },
        {
          name: "location",
          width: "",
          label: "Location",
          class: ""
        },
        {
          name: "TCN",
          width: "",
          label: "TCN",
          class: ""
        },
        {
          name: "details",
          width: "",
          label: "Details",
          class: ""
        }
      ],
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Workstation Logs",
          route: ""
        }
      ],
      apikey: "all",
      serviceOutlet: "all",
      search_text: "",
      date_from: this.$moment(new Date()).format("YYYY-MM-DD"),
      date_to: this.$moment(new Date()).format("YYYY-MM-DD"),
      apikeylogs: [],
      options: [],
      offset: 0,
      limit: 20,
      pagination: {
        total_records: 0,
        total_page: 0,
        current_page: 1,
        per_page_data: 15,
        received_per_page_data: 15
      },
      currentIndex: -1,
      loadingData: false,
      hasData: false,
      msg: "",
      key: 0
    };
  },

  mounted() {
    this.$store.dispatch(FETCH_CLIENTS);
    this.options = [{ id: "all", name: "all" }, this.getClients];
    this.$store.dispatch(FETCH_CLIENT_APIKEYS, this.serviceOutlet);
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
  },

  methods: {
    search() {
      this.pagination = {
        total_records: 0,
        total_page: 0,
        current_page: 1,
        per_page_data: 15,
        received_per_page_data: 15
      };
      this.appData();
    },
    advSearch() {
      this.search_text = "";
      this.search();
    },

    refreshData() {
      this.pagination = {
        total_records: 0,
        total_page: 0,
        current_page: 1,
        per_page_data: 15,
        received_per_page_data: 15
      };
      this.appData();
    },
    selectedPage(n) {
      this.pagination.current_page = n;
      this.appData();
    },
    nextPage() {
      this.pagination.current_page = this.pagination.current_page + 1;
      this.appData();
    },
    lastPage() {
      this.pagination.current_page = this.pagination.total_page;
      this.appData();
    },
    firstPage() {
      this.pagination.current_page = 1;
      this.appData();
    },
    previousPage() {
      this.pagination.current_page = this.pagination.current_page - 1;
      this.appData();
    },
    loadApiKeys(option) {
      this.apikey = "all";

      this.$store.dispatch(FETCH_CLIENT_APIKEYS, option.id);
    },
    appData() {
      this.apikeylogs = [];
      this.getLoadingData = true;
      if (this.serviceOutlet == "all" || this.serviceOutlet == null) {
        var location = "all";
      } else {
        location = this.serviceOutlet.id;
      }
      if (this.apikey == "all" || this.apikey == null) {
        api_key = "all";
      } else {
        var api_key = this.apikey.id;
      }
      var data = {
        service_outlet: location,
        date_from: this.date_from,
        date_to: this.date_to,
        api_key_id: api_key,
        search_text: this.search_text,

        page: this.pagination.current_page
      };
      this.$store
        .dispatch(FETCH_APIKEY_LOGS, data)
        .then(res => {
          this.getLoadingData = false;
          this.apikeylogs = res.data;
          this.pagination.total_records = res.total_record;
          this.pagination.total_page = res.page_range;
          this.pagination.received_per_page_data = res.data.length;
          this.pagination.per_page_data = 15;

          // console.log(res);
        })
        .catch(err => {
          this.getLoadingData = false;
          this.msg = err.error;
        });
    }
  }
};
</script>
